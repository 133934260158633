import { CountryType, ItemCode, ProductTypes } from '../types/shipping-data'

export const PAGE_PATH_NAME = {
  MENU: '/',
  MAIL_SEND: '/mailsend',
  SENT: '/sent',
  DEVICE_REGIST: '/deviceregist',
  DEVICE_REGISTED: '/deviceregisted',
  ERROR: '/error',
  COMPANY_LIST: '/corporations',
  COMPANY_DETAILS: '/corporations/details',
  COMPANY: '/corporations/company',
  DICTIONARY_DELETE_COMPLETE: '/dictionary/deletecomplete',
  DICTIONARY_UPLOAD_COMPLETE: '/dictionary/uploadcomplete',
  DEVICES: '/devices',
  DEVICE_DETAILS: '/devices/device-details',
  DEVICE_IMPORT: '/device-import',
  USER_MANAGEMENT: '/user-management',
  NOTIFICATION_MANAGEMENT: '/notifications',
  NOTIFICATION_CREATE: '/notifications/create-notification',
  LOGIN: '/login',
  NOTIFICATION_DETAILS: '/notifications/notification-details',
  EULA_MANAGEMENT: '/eula',
  SHIPPING_DATA_IMPORT: '/shipping-data-import',
  SHIPPING_DATA_IMPORT_DETAILS: '/shipping-data-import/shipping-data-details',
  DEVICE_URL_MAPPING_LIST: '/device-url-mapping',
  EXPORT_CSV_TO_SFDC: '/csv-to-sfdc',
  REGION_MAPPING_LIST: '/region-mapping-list'
}

export const PAGE_PATH_NAME_TITLE: { [key: string]: string } = {
  devices: 'Devices',
  mailsend: 'User Management',
  sent: 'Sent',
  deviceregist: 'Device Import',
  deviceregisted: 'Device Registered',
  error: 'Error',
  corporations: 'Corporations',
  corporatedetail: 'Corporation Details',
  deletecomplete: 'Delete Completed',
  uploadcomplete: 'Upload Completed',
  eula: 'EULA',
  company: 'Corporate Detail',
  'device-import': 'Device Import',
  'user-management': 'User Management',
  notifications: 'Notifications',
  'notification-update': 'Notification Update',
  'shipping-data-import': 'Shipping Data Import',
  'shipping-data-details': 'Shipping Data Details',
  'device-url-mapping': 'Device URL Mappings',
  'notification-details': 'Notification Details',
  'device-details': 'Device Details',
  'csv-to-sfdc': 'Export CSV for SFDC',
  'region-mapping-list': 'Region Mappings'
}

/**
 * 各画面名
 */
export const PAGE_VIEW_NAME = {
  // ［メニュー］画面
  MENU: 'メニュー画面',
  // ［アカウント作成メール送信］画面
  MAIL_SEND: 'アカウント作成メール送信画面',
  // ［アカウント作成メール送信完了］画面
  SENT: 'アカウント作成メール送信完了画面',
  // ［端末一括登録］画面
  DEVICE_REGIST: '端末一括登録画面',
  // ［端末一括登録完了］画面
  DEVICE_REGISTED: '端末一括登録完了画面',
  // ［エラー］画面
  ERROR: 'エラー画面',
  // [法人一覧]画面
  COMPANY_LIST: '法人一覧画面',
  // [法人詳細]画面
  COMPANY: '法人詳細画面',
  // [辞書削除完了]画面
  DICTIONARY_DELETE_COMPLETE: '辞書削除完了画面',
  // [辞書ファイルアップロード完了]画面
  DICTIONARY_UPLOAD_COMPLETE: '辞書ファイルアップロード完了画面'
}

/**
 * 各画面情報MAP
 */
export const PAGE_INFO_MAP = [
  { path: PAGE_PATH_NAME.MENU, name: PAGE_VIEW_NAME.MENU },
  { path: PAGE_PATH_NAME.MAIL_SEND, name: PAGE_VIEW_NAME.MAIL_SEND },
  { path: PAGE_PATH_NAME.SENT, name: PAGE_VIEW_NAME.SENT },
  {
    path: PAGE_PATH_NAME.DEVICE_REGIST,
    name: PAGE_VIEW_NAME.DEVICE_REGIST
  },
  {
    path: PAGE_PATH_NAME.DEVICE_REGISTED,
    name: PAGE_VIEW_NAME.DEVICE_REGISTED
  },
  {
    path: PAGE_PATH_NAME.ERROR,
    name: PAGE_VIEW_NAME.ERROR
  },
  {
    path: PAGE_PATH_NAME.COMPANY_LIST,
    name: PAGE_VIEW_NAME.COMPANY_LIST
  },
  {
    path: PAGE_PATH_NAME.COMPANY,
    name: PAGE_VIEW_NAME.COMPANY
  },
  {
    path: PAGE_PATH_NAME.DICTIONARY_DELETE_COMPLETE,
    name: PAGE_VIEW_NAME.DICTIONARY_DELETE_COMPLETE
  },
  {
    path: PAGE_PATH_NAME.DICTIONARY_UPLOAD_COMPLETE,
    name: PAGE_VIEW_NAME.DICTIONARY_UPLOAD_COMPLETE
  }
]

/**
 * 各メニュー名
 */
export const MENU_NAME = {
  // ［メニュー］画面
  MENU: 'メニュー',
  // ［アカウント作成メール送信］画面
  MAIL_SEND: 'アカウント作成メール送信',
  // ［端末一括登録］画面
  DEVICE_REGIST: '端末一括登録',
  // [法人一覧]画面
  COMPANY_LIST: '法人一覧'
}

export const TABLE_ROW_NUMBER_PER_PAGE = [30, 50, 100, 200]

/**
 * 各APIのURL
 */
export const API_URL = {
  AUTHENTICATE_API: '/iotconsoleapi-global-bo/api/authenticate',
  SHIPPING_DATA_DETAILS: '/iotconsoleapi-bo/api/shipping-data-details',
  SHIPPING_DATA_IMPORT:
    '/iotconsoleapi-global-bo/api/private/shipping-data-list',
  // (バックオフィス)統合認証API
  INTEGRATED_API: '/iotconsoleapi-bo/api/pub/integrated',
  // 招待メール送信API
  INVITATION_SEND_MAIL_API: '/iotconsoleapi-bo/api/auth/invitationsendmail',
  // 端末登録API
  DEVICE_REGISTER_API: '/iotconsoleapi-bo/api/auth/deviceregister',
  // 法人一覧取得API
  CORPORATION_LIST_API: '/iotconsoleapi-global-bo/api/private/corporation-list',
  // 利用言語一覧API
  LANGUAGE_LIST_API: '/iotconsoleapi-bo/api/auth/languagelist',
  // アカウント情報存在確認API(管理コンソール)
  ACCOUNT_CHECK_CONSOLE_API: '/iotconsoleapi-bo/api/auth/accountcheck',
  // 法人情報取得API
  CORPORATION_INFO_API: '/iotconsoleapi-bo/api/auth/corporationinfo',
  // 辞書登録済み確認API
  GLOSSARY_EXISTS_API: '/iotconsoleapi-bo/api/auth/glossaryexists',
  // 辞書削除API
  GLOSSARY_DELETE_API: '/iotconsoleapi-bo/api/auth/glossarydelete',
  // 辞書登録API
  GLOSSARY_REGISTER_API: '/iotconsoleapi-bo/api/auth/glossaryregister',
  // Gūguru oauth api
  GOOGLE_AUTH_API: 'https://www.googleapis.com/oauth2/v3',
  SERVER_REGION_LIST_API:
    '/iotconsoleapi-global-bo/api/private/server-region-list',
  DEVICE_DETAILS_API: '/iotconsoleapi-global-bo/api/private/device-details',
  DEVICE_LIST_API: '/iotconsoleapi-global-bo/api/private/devicelist',
  DEVICE_IMPORT_REGISTER_API:
    '/iotconsoleapi-global-bo/api/private/registerdevices',
  DEVICE_IMPORT_LIST_API:
    '/iotconsoleapi-global-bo/api/private/deviceimporthistorylist',
  NOTIFICATION_LIST_API:
    '/iotconsoleapi-global-bo/api/private/notification-list',
  NOTIFICATION_BY_ID:
    '/iotconsoleapi-global-bo/api/private/get-notification-detail',
  DEVICE_URL_MAPPING_LIST:
    '/iotconsoleapi-global-bo/api/private/device-url-mapping-list',
  REGION_MAPPING_LIST:
    '/iotconsoleapi-global-bo/api/private/region-mapping-list'
}

/**
 * WEBストレージ
 */
export const WEB_STORAGE = {
  // RMS認証成功後セッションから取得したログイン情報
  LOGIN_INFO: 'iotLoginInfo',
  AUTH_INFO: 'iotAuthInfo'
} as const

/**
 * URLパラメータに付与されるkey名
 */
export const URL_PARAMETER_KEY = {
  // ワンタイムキー
  ONE_TIME_KEY: 'key'
} as const

/**
 * 権限ID
 */
export const ACCESSCONTROL_ID = {
  // 開発者権限
  DEV: 'DEV'
}

/**
 * 権限名
 */
export const ACCESSCONTROL_NAME = {
  // 開発者権限
  DEV: '開発'
}

/**
 * 権限情報MAP
 */
export const ACCESSCONTROL_MAP = [
  { id: ACCESSCONTROL_ID.DEV, name: ACCESSCONTROL_NAME.DEV }
] as const

/**
 * APIリクエスト設定
 */
export const API_REQUEST_CONFIG = {
  // APIリクエスト時のタイムアウト時間(ミリ秒)
  TIMEOUT: 30000
} as const

export const GOOGLE_CLIENT_ID =
  '331230062057-fl2et96bhepk9g8npjpf5gojg4krqcrh.apps.googleusercontent.com'

/**
 * 日付フォーマット(yyyy/MM形式)(言語関係なく統一)
 */
export const DATE_FORMAT = 'yyyy/MM' as const

/**
 * 日付フォーマット(yyyy/MM/dd形式)(言語関係なく統一)
 */
export const DATE_FORMAT_YYYYMMDD = 'yyyy/MM/dd' as const

/**
 * file upload
 * its 5MB
 */
export const MAX_FILE_UPLOAD_SIZE = 5 * 1024 * 1024

export const SHIPPING_DATA_CSV_ITEMCODE_ENUM = ['PTS', 'PTS KO', 'PTS MA']

export const Product_Types: ProductTypes = {
  1: 'POCKETALK W',
  2: 'POCKETALK S',
  3: 'POCKETALK S PLUS',
  4: 'POCKETALK S2',
  5: 'POCKETALK S2 PLUS'
}

export const Item_Code: ItemCode = {
  1: 'PTS2 Warehouse',
  2: 'PTS2 Global common(pocketalk) 2 year',
  3: 'PTS2 Global common(pocketalk) 5 year',
  4: 'PTS2 Global common(pocketalk) for rental',
  5: 'PTS2 Global common(pocketalk) for JP sales team',
  6: 'PTS2 Korean model 2 year',
  7: 'PTS2 Malaysia model 2 year',
  8: 'PTS2 Ventana 2 year',
  9: 'PTS2 Ventana 5 year'
}

export const Country: CountryType = {
  JP: 'Japan',
  US: 'USA',
  EU: 'Europe'
}

export const modeEnums: { [key: number]: string } = {
  1: 'None',
  10: 'JP Standard',
  11: 'Tmall',
  12: 'JP Business',
  13: 'JP Business with Group Translation',
  14: 'eSIM Disabled',
  15: 'eSIM Disabled for China mobile',
  40: 'US/CA/EU Business',
  50: 'Business mode',
  51: 'Business mode with Camera'
}

export const overwriteSimEnums: { [key: number]: string } = {
  0: 'Add eSIM expiration date',
  1: 'Overwrite eSIM expiration date'
}

export const salesRegionEnums: { [key: string]: string } = {
  '001': 'US / Canada',
  '002': 'EU / UK',
  '003': 'JP',
  '004': 'Asia',
  '999': 'Global'
}

export const csvDataHeader = [
  'Corp Id',
  'Corp Name',
  'Sales Region',
  'eSIM Valid Duration',
  'IMEI1',
  'IMEI2',
  'Product Type',
  'Mode',
  'Overwrite Sim'
]
export const visibleColumns = [
  'Corp Id',
  'Corp Name',
  'Sales Region',
  'eSIM Valid Duration',
  'IMEI1',
  'IMEI2',
  'Product Type',
  'Mode',
  'Overwrite Sim'
]
