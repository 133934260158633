import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import SimpleBar from 'simplebar-react'
import { format } from 'date-fns'

import { Box, DialogActions, ListItemText, Typography } from '@mui/material'
import PageTemplate from '../PageTemplate'
import AddUserDialog from '../../Modecules/AddUser'
import Button from '../../Atoms/Buttons'
import { useStyles } from '../../../themes'
import Table from '../../Templates/_Table'
import Pagination from '../../Modecules/Pagination/Pagination'
import {
  PAGE_PATH_NAME,
  TABLE_ROW_NUMBER_PER_PAGE
} from '../../../constants/constants'
import usePagination from '../../../hooks/usePagination'
import { DIRECTION_TYPE, SortConfig } from '../../../types/datatable'
import { IColumnProps } from '../../../types/table'
import Loader from '../../Atoms/Loader/Loader'
import { ReactComponent as ImportIconUp } from '../../../images/icon/import-icon-up.svg'
import { ReactComponent as ImportIcon } from '../../../images/icon/import-icon.svg'

import THeader from '../../Modecules/Table/THeader'
import TBody from '../../Modecules/_Table/TBody'
import TRow from '../../Modecules/_Table/TRow'
import TCell from '../../Modecules/_Table/TCell'
import {
  useCorporationSyncMutation,
  useLazyShippingDataListQuery,
  useShippingCsvFileUploadMutation
} from '../../../state/features/shipping-data/shippingDataSlice'
import DropFileUpload from '../../Atoms/DropFIleUpload'
import CustomModal from '../../Atoms/CustomModal'
import { ReactComponent as DownloadIcon } from '../../../images/icon/download.svg'
import { ReactComponent as CsvIcon } from '../../../images/icon/shipping-csv-icon.svg'
import useShippingImportStyles from './style'

import useCsvValidator from '../../../hooks/useCsvValidator'
import ShippingDataModalTable from '../../Modecules/ShippingDataModalTable'
import CustomTooltip from '../../Atoms/Tooltip'
import { updateSortConfig } from '../../../utils/datatable/dataTableUtil'
import CustomizedAccordions from '../../Modecules/Accordion'
import {
  ShippindFileUploadErrorDetail,
  ShippingCsvFileUploadResponse
} from '../../../types/shipping-data'

const ShippingDataImport = (): JSX.Element => {
  const [addUserModal, setAddUserModal] = useState<boolean>(false)
  const [loadingState, setLoadingState] = useState<boolean>(false)
  const [response, setResponse] = useState<{
    status: boolean
    message: string
  } | null>(null)

  const [openNewImportModal, setOpenNewImportModal] = useState<boolean>(false)
  const { handleUploadCsvFile, fileInfo, csvFileData, reset } =
    useCsvValidator()

  const modalClass = useShippingImportStyles()
  const classes = useStyles()
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: '',
    direction: DIRECTION_TYPE.none
  })
  const { t } = useTranslation()
  const [shippingCsvFileUpload, { isLoading, isSuccess }] =
    useShippingCsvFileUploadMutation()



  const [serverFileErrors, setServerFileErrors] = useState<
    ShippindFileUploadErrorDetail[]
  >([])


  useEffect(() => {
    setLoadingState(isLoading)
  }, [isLoading])

  useEffect(() => {
    if (response && response.status) {
      setAddUserModal(false)
      toast.success(response.message)
    } else if (response && !response.status) {
      setAddUserModal(false)
      toast.error(response.message)
    }
  }, [response])

  const [filterQuery, setFilterQuery] = useState({
    sortDirection: 'DESC',
    colName: 'registerDt',
    rowLimitPerPage: TABLE_ROW_NUMBER_PER_PAGE[2],
    currentPageNumber: 0
  })

  const [
    trigger,
    {
      data: shippingDataImportList,
      isSuccess: shippingDataListIsSuccess,
      isFetching: shippingDataListIsReloading
    }
  ] = useLazyShippingDataListQuery()


  
  const [corporationSync, { 
    isSuccess: corporationSyncIsSuccess ,
    isError: corporationSyncIsError,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    error: corporationSyncError,
    isLoading : corporationSyncIsLoading
  }] = useCorporationSyncMutation()



  const {
    currentPage,
    // setCurrentPage,
    onClickPrevPage,
    onClickNextPage,
    rowsPerPage,
    // setRowsPerPage,
    onChangeRowNumber,
    onClickFirstPage,
    onClickLastPage,
    changeCurrentPage
  } = usePagination(
    TABLE_ROW_NUMBER_PER_PAGE[2],
    0,
    shippingDataImportList ? shippingDataImportList?.total_count : 0
  )

  useEffect(() => {
    void trigger(filterQuery, false)
  }, [filterQuery, trigger])

  useEffect(() => {
    setFilterQuery((prev) => ({
      ...prev,
      currentPageNumber: currentPage,
      rowLimitPerPage: rowsPerPage
    }))
  }, [currentPage, rowsPerPage])

  useEffect(() => {
    if (isSuccess) {
      void trigger(filterQuery, false)
      toast.success(t('Shipping Data Import Success.'))
      setOpenNewImportModal(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  const sortFieldsNameReplace = (substr: string) => {
    if (substr === 'fileName') {
      return 'fileName'
    }
    if (substr === 'register_user_name') {
      return 'registerUserName'
    }
    if (substr === 'register_dt') {
      return 'registerDt'
    }

    return ''
  }

  const onClickSortButton = (key: string) => {
    const sortConfigUpdate: SortConfig = updateSortConfig(key, sortConfig)
    setSortConfig(sortConfigUpdate)
    setFilterQuery({
      ...filterQuery,
      sortDirection:
        sortConfigUpdate?.key === '' ? 'DESC' : sortConfigUpdate.direction,
      colName:
        sortConfigUpdate?.direction === DIRECTION_TYPE.none
          ? 'registerDt'
          : sortFieldsNameReplace(key)
    })
    changeCurrentPage(0)
  }

  const columnProps: IColumnProps = [
    {
      key: 'fileName',
      name: 'File Name',
      colWidth: 185,
      colMaxWidth: 185,
      isSort: true
    },
    {
      key: 'register_user_name',
      name: 'Registrant',
      colWidth: 120,
      colMaxWidth: 120,
      isSort: true
    },
    {
      key: 'register_dt',
      name: 'Import Date',
      colWidth: 143,
      colMaxWidth: 143,
      isSort: true
    }
  ]


  const handleCorpSync = async () => {

      await corporationSync().unwrap();
      if (corporationSyncIsSuccess) {
        toast.success("Corporation synced successfully.");
      }
      
  }

  useEffect(() => {

    if (corporationSyncError || corporationSyncIsError) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      toast.error(corporationSyncError?.data?.message || 'Corporation sync failed.');
    }
  }, [corporationSyncIsError,corporationSyncError])
    


  const handleOpenModal = () => {
    setServerFileErrors([])
    setOpenNewImportModal(true)
    reset()
  }

  const handleImportData = () => {
    if (fileInfo) {
      setLoadingState(true)
      const reader = new FileReader()
      const readFile = new Promise((resolve, reject) => {
        reader.onload = (event) => {
          resolve(event?.target?.result)
        }

        reader.onerror = (error) => {
          reject(error)
        }

        reader.readAsText(fileInfo)
      })

      readFile
        .then(() => {
          const formData = new FormData()
          formData.append('csv_file', fileInfo)

          setServerFileErrors([])

          shippingCsvFileUpload(formData)
            .unwrap()
            .then((res: ShippingCsvFileUploadResponse) => {
              if (res?.data?.status === 200) {
                setServerFileErrors([])
                setOpenNewImportModal(false)
              }
            })
            .catch((error: ShippingCsvFileUploadResponse) => {
              if (
                error?.data?.status === 400 &&
                error?.data?.error_count &&
                error?.data?.error_count > 0 &&
                error?.data?.errors &&
                error?.data?.errors.length > 0
              ) {
                toast.error('The uploaded file contains errors.')
                setServerFileErrors(error?.data?.errors)
                setLoadingState(false)
              } else if (error?.data?.status === 400 && error?.data?.message) {
                toast.error(error?.data?.message)
                setLoadingState(false)
              } else {
                toast.error('Server Error.')
                setLoadingState(false)
              }
            })
        })
        .catch(() => {
          toast.error('Attached file has been changed.')
          setLoadingState(false)
        })
        
    }
  }

  return (
    <PageTemplate>
      <CustomModal
        isOpen={openNewImportModal}
        onClose={() => {
          setOpenNewImportModal(false)
        }}
      >
        <>
          <Box
            sx={{
              display: fileInfo && 'flex',
              gap: '30px',
              width: '100%'
            }}
          >
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'end'
                }}
              >
                <Typography
                  className={modalClass.topTextStyle}
                  sx={{
                    marginBottom: '7px !important',
                    marginTop: '15px !important'
                  }}
                >
                  {t('shippingDataImport.headerTitle3')}
                </Typography>
              </Box>
              <DropFileUpload
                onChange={(e) => {
                  setServerFileErrors([])
                  // eslint-disable-next-line
                  handleUploadCsvFile(e)
                }}
                onRemove={() => {
                  reset()
                }}
                inputFieldMaxWidth='433px'
                acceptedFilesTypes={['csv']}
                disabled={false}
                title={t('deviceImport.dragFileImportTitle')?.split('-')[0]}
                titleError={
                  t('deviceImport.dragFileUploadTitle')?.split('-')[0]
                }
                defValue={fileInfo}
                titleMiddle={
                  t('deviceImport.dragFileImportTitle')?.split('-')[1]
                }
                titleBottom={
                  t('deviceImport.dragFileImportTitle')?.split('-')[2]
                }
                inputFieldMaxFileSize={5 * 1024 * 1024} // 5 MB
                fileSizeError={t('deviceImport.fileSizeError')}
                multipleFilesError={t('deviceImport.multipleFilesError')}
                fileTypeError={t('deviceImport.fileTypeError')}
                encodeError={t('deviceImport.encodeError')}
                isLoading={loadingState}
              />
            </Box>

            {csvFileData && csvFileData.length > 0 && (
              <Box>
                <Typography className={modalClass.topTextStyle}>
                  {t('deviceImport.dataSize')}
                </Typography>
                <Typography className={modalClass.topTextMutedStyle}>
                  {fileInfo ? (fileInfo.size / 1024).toFixed(2) : '--'} KB
                </Typography>
              </Box>
            )}
          </Box>

          {fileInfo && (
            <ShippingDataModalTable
              newCsvData={csvFileData}
              errors={serverFileErrors}
            />
          )}
          {!fileInfo && csvFileData?.length === 0 && (
            <Box
              sx={{
                paddingTop: '6px'
              }}
            >
              <CustomizedAccordions title='Instruction' marginBottom='0px'>
                <ListItemText
                  sx={{
                    paddingLeft: '10px'
                  }}
                >

                  <Typography fontSize='12px' color='#6C7778' height='19.5px'>
                    * You can not specify files other than CSV format and UTF-8
                    encoding files.
                  </Typography>
                  <Typography fontSize='12px' color='#6C7778' height='19.5px'>
                    * Header row cannot be specified.
                  </Typography>
                  <Typography fontSize='12px' color='#6C7778' height='19.5px'>
                    * When registering multiple IMEIs, separate them with line
                    breaks.
                  </Typography>
                  
                  <Typography fontSize='12px' color='#6C7778' height='19.5px'>
                    * Set a comma and double quotes as the delimiter between IMEI and model name. 
                  </Typography>
                  <Typography fontSize='12px' color='#6C7778' height='19.5px'>
                    * Please set the model name as a numerical value as follows.
                  </Typography>
                  <Typography fontSize='12px' color='#6C7778' height='19.5px'>
                    1 → POCKETALKW
                  </Typography>
                  <Typography fontSize='12px' color='#6C7778' height='19.5px'>
                    2 → POCKETALKS
                  </Typography>
                  <Typography fontSize='12px' color='#6C7778' height='19.5px'>
                    3 → POCKETALKSPlus
                  </Typography>
                  <Typography fontSize='12px' color='#6C7778' height='19.5px'>
                    4 → POCKETALKS2
                  </Typography>
                  <Typography fontSize='12px' color='#6C7778' height='19.5px'>
                    5 → POCKETALKS2Plus
                  </Typography>
                </ListItemText>
              </CustomizedAccordions>
            </Box>
          )}

          <DialogActions
            className={modalClass.btnBox}
            sx={{
              marginTop: '20px'
            }}
          >
            <Button
              variant='outlined'
              className={modalClass.cancelBtn}
              onClick={() => setOpenNewImportModal(false)}
              disabled={loadingState}
            >
              Cancel
            </Button>
            <Button
              variant='outlined'
              startIcon={
                loadingState ? (
                  <Loader colorCode='#939598' size='medium' />
                ) : (
                  <DownloadIcon />
                )
              }
              className={modalClass.importBtn}
              onClick={handleImportData}
              sx={{
                width: '119.5px !important'
              }}
              disabled={csvFileData?.length === 0 || loadingState}
            >
              Import
            </Button>
          </DialogActions>
        </>
      </CustomModal>
      {/* <div className={classes.searchBlock}> */}
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '8px'
        }}
      >
        <Button className={classes.refreshBtn} color='primary'
          onClick={()=>{
            void handleCorpSync()
          }}
          disabled={corporationSyncIsLoading}
        >
          Refresh corp_id
        </Button>
        <Button
            className={classes.importBtn}
            color='primary'
            onClick={handleOpenModal}
          >
            <ImportIcon />
            {t('deviceImport.newImport')}
          </Button>
      </Box>
      {/* </div> */}
      <AddUserDialog
        handleClose={() => {
          setAddUserModal(false)
        }}
        open={addUserModal}
        setResponse={setResponse}
      />
      <Box className={classes.tableCard}>
        <div>
          <Typography className={classes.tableDataCount}>
            {`${t('shippingDataImport.headerTitle2')}`} (
            {shippingDataImportList?.total_count ?? 0})
          </Typography>
        </div>

        {shippingDataImportList?.total_count === 0 ? (
          <div className={classes.dataNotFoundBlock}>
            <CsvIcon />
            <p
              className={`${classes.noFountText} ${classes.mt_0}`}
              style={{ marginTop: '4px' }}
            >
              {t('shippingDataImport.noDataTitle')}
            </p>
            <p className={`${classes.noAvailableText} ${classes.mt_0}`}>
              {t('shippingDataImport.noDataMessage')}
            </p>

            <Box>
              <Button
                className={classes.newImportBtn}
                color='primary'
                onClick={handleOpenModal}
                sx={{ marginTop: '18px !important' }}
              >
                <Box>
                  <ImportIconUp />
                </Box>
                <Typography>{t('deviceImport.newImport')}</Typography>
              </Button>
            </Box>
          </div>
        ) : (
          <Box className={classes.dataTableWrap}>
            <SimpleBar>
              <Table>
                <THeader
                  clickSortButton={onClickSortButton}
                  sortConfig={sortConfig}
                  columnProperties={columnProps}
                />
                <TBody
                  isSuccess={shippingDataListIsSuccess}
                  columnProperties={columnProps}
                >
                  {shippingDataImportList?.details?.map((element) => (
                    <TRow
                      key={element.id}
                      index={element.id}
                      hyperlink={{
                        valid: true,
                        path: PAGE_PATH_NAME.SHIPPING_DATA_IMPORT_DETAILS,
                        state: {
                          key: 'shipping_details_id',
                          value: element.id
                        }
                      }}
                    >
                      <TCell
                        colMaxWidth={columnProps[0].colMaxWidth}
                        colWidth={columnProps[0].colWidth}
                        isReloading={shippingDataListIsReloading}
                        key='file_name'
                      >
                        <CustomTooltip
                          color='#007EB4'
                          toolTipTitle={element.file_name}
                          maxWidth={columnProps[0].colWidth}
                        />
                      </TCell>
                      <TCell
                        colMaxWidth={columnProps[1].colMaxWidth}
                        colWidth={columnProps[1].colWidth}
                        isReloading={shippingDataListIsReloading}
                        key='register_user_name'
                      >
                        <CustomTooltip
                          toolTipTitle={element.register_user_name}
                          maxWidth={columnProps[1].colWidth}
                        />
                      </TCell>
                      <TCell
                        colMaxWidth={columnProps[2].colMaxWidth}
                        colWidth={columnProps[2].colWidth}
                        isReloading={shippingDataListIsReloading}
                        key='register_dt'
                      >
                        {format(
                          new Date(element.register_dt),
                          'MMM d, yyyy  HH:mm'
                        )}
                      </TCell>
                    </TRow>
                  ))}
                </TBody>
              </Table>
            </SimpleBar>
            {shippingDataListIsReloading && shippingDataListIsSuccess ? (
              <Box
                sx={{
                  width: '40px',
                  height: '40px',
                  position: 'absolute',
                  top:
                    // eslint-disable-next-line no-nested-ternary
                    shippingDataImportList?.total_count === 1
                      ? '10%'
                      : shippingDataImportList?.total_count === 2
                      ? '25%'
                      : '45%',
                  left: '50%',
                  zIndex: '1'
                }}
              >
                <Loader colorCode='#939598' />
              </Box>
            ) : null}
            <div className={classes.dataPagination}>
              <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(
                  // eslint-disable-next-line no-unsafe-optional-chaining
                  (shippingDataImportList
                    ? shippingDataImportList.total_count ?? 0
                    : 0) / rowsPerPage
                )}
                clickPrevPageButton={() => {
                  onClickPrevPage()
                }}
                clickNextPageButton={() => {
                  onClickNextPage()
                }}
                rowNumberPerPages={TABLE_ROW_NUMBER_PER_PAGE}
                currentRowNumberPerPage={rowsPerPage}
                changeRowNumber={onChangeRowNumber}
                containFirstAndLastButton
                clickFirstPageButton={onClickFirstPage}
                clickLastPageButton={onClickLastPage}
                pageDataIndexFrom={currentPage * rowsPerPage + 1}
                pageDataIndexTo={
                  // eslint-disable-next-line no-nested-ternary
                  (currentPage + 1) * rowsPerPage >
                  (shippingDataImportList
                    ? shippingDataImportList.total_count
                    : 0)
                    ? shippingDataImportList
                      ? shippingDataImportList.total_count
                      : 0
                    : (currentPage + 1) * rowsPerPage
                }
                totalDataRows={
                  shippingDataImportList
                    ? shippingDataImportList.total_count
                    : 0
                }
              />
            </div>
          </Box>
        )}
      </Box>
    </PageTemplate>
  )
}

export default ShippingDataImport
